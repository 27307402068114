import React, { useState } from "react";
import "./App.css";
import styled from "styled-components";
//animation
import { motion } from "framer-motion";
//components
import ShowWeather from "./components/ShowWeather";
import Search from "./components/Search";

function App() {
  const [weather, setWeather] = useState(null);
  const [input, setInput] = useState("");
  const [searchString, setSearchString] = useState(null);

  return (
    <Container>
      <Search
        input={input}
        setInput={setInput}
        setSearchString={setSearchString}
      />
      <ShowWeather
        weather={weather}
        setWeather={setWeather}
        searchString={searchString}
      />
      <Impressum>
        <p>
          Martin Loeffler / 2021 / build with{" "}
          <a href='https://www.weatherapi.com/'>weatherapi.com</a> /{" "}
          <a href='https://impressum.loefflermartin.de'>Impressum</a>
        </p>
      </Impressum>
    </Container>
  );
}

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Impressum = styled.div`
  position: absolute;
  bottom: 0;
  text-align: center;
  font-weight: bolder;
  margin-bottom: 0.3rem;
  a {
    text-decoration: none;
    color: black;
  }
`;

export default App;
