import React from "react";
import styled from "styled-components";

const Search = ({ input, setInput, setSearchString }) => {
  const userInput = (e) => {
    setInput(e.target.value);
  };

  const searchWeather = () => setSearchString(input);

  const checkForEnter = (key) => {
    if (key.keyCode === 13 && input !== "") {
      searchWeather();
    }
  };

  return (
    <SearchInput>
      <input
        onChange={userInput}
        type='text'
        value={input}
        placeholder='enter city/region and country'
        onKeyDown={checkForEnter}
      />
      <button onClick={searchWeather}>search</button>
    </SearchInput>
  );
};

const SearchInput = styled.div`
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  @media (max-width: 350px) {
    flex-direction: column;
  }
  input {
    width: 30rem;
    font-size: 1rem;
    border-radius: 0.75rem;
    margin: 0 0.5rem;
    height: 3rem;
    padding: 0.5rem;
    @media (max-width: 600px) {
      width: 15rem;
    }
    :focus {
      outline: none;
    }
  }
  button {
    font-size: 1rem;
    border-radius: 0.5rem;
    margin: 0 0.5rem;
    padding: 0.5rem;
    height: 3rem;
  }
`;

export default Search;
