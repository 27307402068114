import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

const ShowWeather = ({ weather, setWeather, searchString }) => {
  useEffect(() => {
    if (searchString !== null) {
      axios
        .get(
          `https://api.weatherapi.com/v1/current.json?key=${process.env.REACT_APP_WEATHER_API}&q=${searchString}`
        )
        .then((data) => {
          setWeather(data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [searchString]);

  return (
    <div>
      {weather && (
        <>
          <h1>{`${weather.location.name}, ${weather.location.country}`}</h1>
          <Temp>
            <h2>{weather.current.temp_c} °C</h2>
          </Temp>
          <FeelsLike>
            <p>feels like {weather.current.feelslike_c} °C</p>
          </FeelsLike>
          <Condition>
            <img src={weather.current.condition.icon} alt='' />
            <h3>{weather.current.condition.text}</h3>
          </Condition>
          <Wind>
            <p>
              wind: {weather.current.wind_kph}kph from{" "}
              {weather.current.wind_dir}
            </p>
          </Wind>
        </>
      )}
    </div>
  );
};

const Temp = styled.div`
  text-align: center;
  h2 {
    font-size: 4rem;
  }
`;

const Condition = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    margin: 1rem 1rem;
  }
  img {
    margin: 1rem 1rem;
  }
`;

const FeelsLike = styled(Condition)`
  p {
    font-size: 1.5rem;
    font-style: italic;
    padding: 0.5rem;
    font-weight: bolder;
  }
`;

const Wind = styled(Condition)`
  p {
    font-size: 1.5rem;
    padding: 0.5rem;
    font-weight: bolder;
  }
`;

export default ShowWeather;
